import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { forwardRef, Ref } from 'react'
import { ApplicationRef, useApplicationOptions } from '../ApplicationOptionsContext'
import { useUserInterface } from '../hooks'
import { getApplicationLogo } from '../utils/getApplicationLogo'

export type UseStylesType = {
  appRef: ApplicationRef
}

const useStyles = makeStyles<Theme, UseStylesType>((theme: Theme) =>
  createStyles({
    image: {
      marginLeft: theme.spacing(1.4),
      marginRight: theme.spacing(1.75),
      flexBasis: theme.spacing(26),
      flexShrink: 0,
      height: (props) => (props.appRef === 'kraken' ? '72px' : '50px'),
      '& svg': {
        width: 'auto',
        maxHeight: '100%',
      },
    },
  })
)

type ApplicationLogoProps = {}

const ApplicationLogo = forwardRef((_props: ApplicationLogoProps, ref?: Ref<HTMLSpanElement>) => {
  const { appRef, tool } = useApplicationOptions()
  const classes = useStyles({
    appRef: appRef ?? tool,
  })
  const { palette } = useUserInterface()

  return (
    <>
      <div className={classes.image}>
        <>
          <span
            ref={ref}
            style={{
              display: 'inline-block',
              height: 1,
              width: tool === 'kraken' ? 56 : 48,
              position: 'absolute',
            }}
          ></span>
          {getApplicationLogo(appRef ?? tool, palette, 'component')}
        </>
      </div>
    </>
  )
})

export default ApplicationLogo
