import { Tool, LanguageTag } from '@griegconnect/krakentools-react-ui'
import { createContext, useContext } from 'react'
import { NotificationsGlobalProps } from './ui/NotificationsButton'

export type ApplicationRef =
  | Tool
  | 'admin'
  | 'explore'
  | 'go'
  | 'haulier'
  | 'manage'
  | 'monitor'
  | 'shiplog'
  | 'placeholder'

export type ApplicationOptionsContextType = {
  name: string
  tool: Tool
  appRef?: ApplicationRef
  tenantsUrlMatchPathPrefix: string
  tenantsUrlMatchPathParam: string
  reloadOnTenantsChange: boolean
  /**
   * Will disable the forced profile completion.
   *
   * This should only be used by applications that has their own way of completing the profile
   *
   * @type {boolean}
   */
  disableProfileCompletion?: boolean
  beforeLogout?: () => Promise<void>
  /**
   * Will only fire once after auth is initialized
   */
  notAuthenticated?: () => Promise<void>
  configurationFilePath: string
  profileModalOpen: string | undefined
  changeProfileModal: (value: string | undefined) => void
  notifications?: NotificationsGlobalProps
  i18n?: {
    /**
     * Supported languages for the application.
     */
    languages?: LanguageTag[]
  }
}

export const ApplicationOptionsContext = createContext<ApplicationOptionsContextType | null>(null)
export const useApplicationOptions = () => useContext(ApplicationOptionsContext)!
