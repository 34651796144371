import { createRoot } from 'react-dom/client'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ApplicationProvider, Tenant } from '@griegconnect/krakentools-react-kraken-app'
import { ReactComponent as Slide } from '@app/common/slides/slide1.svg'
import { PATH_TENANT_BASE } from './constants'
import { App } from './App'
import './config/i18n'

const queryClient = new QueryClient()
export interface AccessDependencies {
  activeTenant: Tenant.Type
}

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <ApplicationProvider
    name="Manage"
    appRef="manage"
    applicationSwitcher
    tenantsUrlMatchPathPrefix={PATH_TENANT_BASE}
    tenantsUrlMatchPathParam="tenantRef"
    confirmInvitation
    onboarding={{
      carouselElements: [
        {
          component: <Slide />,
          title: 'Manage',
          description: 'Easily administrate your users access with roles and permissions',
        },
      ],
    }}
    i18n={{
      languages: ['en-GB', 'nb-NO', 'sv-SE', 'fi-FI'],
    }}
  >
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </ApplicationProvider>
)
